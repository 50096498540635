<template>
    <div class="homeOrder">
        <div class="title">我的优惠券</div>

        <div class="tabss" v-loading="loading">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部" name="first">
                    <!-- 优惠券列表 -->
                    <div class="yhqList">
                        <div class="son" v-for="(item, index) in myCouponList" :key="index">
                            <div class="l">
                                <div class="jiag" v-if="item.type == 2"> <span>{{ item.discount }}</span> 折</div>
                                <div class="jiag" v-else-if="item.type == 3"> ￥<span>{{ item.fixationAmount }}</span> </div>
                                <div class="jiag" v-else-if="item.type == 4"> ￥<span>{{ item.premiseAmount }}</span> </div>
                                <div class="jiag" v-else> ￥<span>{{ item.reduceAmount }}</span> </div>
                                <div class="text" v-if="item.type !== '3'">满{{ item.premiseAmount }}可用</div>
                            </div>
                            <div class="r">
                                <div class="name">{{ item.storeName }}</div>
                                <div class="text"> {{ item.suitType == 1 ? '全部商品可用' : '指定商品可用' }}</div>
                                <div class="btn btn3" v-if="item.status == '0'"@click="searchClick(item)">立即使用</div>
                                <div class="btn btn2" v-if="item.status == '2'">过期失效</div>
                                <div class="btn btn2" v-if="item.status == '1'">已使用</div>
                                <div class="prompt">有效期至 {{ item.createTime }}</div>
                            </div>
                            <img src="@/assets/mjq.png" v-if="item.type == 1" alt="">
                            <img src="@/assets/jeq.png" v-if="item.type == 3" alt="">
                            <img src="@/assets/kmq.png" v-if="item.type == 4" alt="">
                            <img src="@/assets/zkq.png" v-if="item.type == 2" alt="">
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="未使用" name="second">
                    <div class="yhqList">
                        <div class="son" v-for="(item, index) in myCouponList" :key="index">
                            <div class="l">
                                <div class="jiag" v-if="item.type == 2"> <span>{{ item.discount }}</span> 折</div>
                                <div class="jiag" v-else-if="item.type == 3"> ￥<span>{{ item.fixationAmount }}</span> </div>
                                <div class="jiag" v-else-if="item.type == 4"> ￥<span>{{ item.premiseAmount }}</span> </div>
                                <div class="jiag" v-else> ￥<span>{{ item.reduceAmount }}</span> </div>
                                <div class="text" v-if="item.type !== '3'">满{{ item.premiseAmount }}可用</div>
                            </div>
                            <div class="r">
                                <div class="name">{{ item.storeName }}</div>
                                <div class="text"> {{ item.suitType == 1 ? '全部商品可用' : '指定商品可用' }}</div>
                                <div class="btn btn3" v-if="item.status == '0'"@click="searchClick(item)">立即使用</div>
                                <div class="btn btn2" v-if="item.status == '2'">过期失效</div>
                                <div class="btn btn2" v-if="item.status == '1'">已使用</div>
                                <div class="prompt">有效期至 {{ item.createTime }}</div>
                            </div>
                            <img src="@/assets/mjq.png" v-if="item.type == 1" alt="">
                            <img src="@/assets/jeq.png" v-if="item.type == 3" alt="">
                            <img src="@/assets/kmq.png" v-if="item.type == 4" alt="">
                            <img src="@/assets/zkq.png" v-if="item.type == 2" alt="">
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="已使用" name="third">
                    <div class="yhqList">
                        <div class="son" v-for="(item, index) in myCouponList" :key="index">
                            <div class="l">
                                <div class="jiag" v-if="item.type == 2"> <span>{{ item.discount }}</span> 折</div>
                                <div class="jiag" v-else-if="item.type == 3"> ￥<span>{{ item.fixationAmount }}</span> </div>
                                <div class="jiag" v-else-if="item.type == 4"> ￥<span>{{ item.premiseAmount }}</span> </div>
                                <div class="jiag" v-else> ￥<span>{{ item.reduceAmount }}</span> </div>
                                <div class="text" v-if="item.type !== '3'">满{{ item.premiseAmount }}可用</div>
                            </div>
                            <div class="r">
                                <div class="name">{{ item.storeName }}</div>
                                <div class="text"> {{ item.suitType == 1 ? '全部商品可用' : '指定商品可用' }}</div>
                                <div class="btn btn3" v-if="item.status == '0'"@click="searchClick(item)">立即使用</div>
                                <div class="btn btn2" v-if="item.status == '2'">过期失效</div>
                                <div class="btn btn2" v-if="item.status == '1'">已使用</div>
                                <div class="prompt">有效期至 {{ item.createTime }}</div>
                            </div>
                            <img src="@/assets/mjq.png" v-if="item.type == 1" alt="">
                            <img src="@/assets/jeq.png" v-if="item.type == 3" alt="">
                            <img src="@/assets/kmq.png" v-if="item.type == 4" alt="">
                            <img src="@/assets/zkq.png" v-if="item.type == 2" alt="">
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="已过期" name="fourth">
                    <div class="yhqList">
                        <div class="son" v-for="(item, index) in myCouponList" :key="index">
                            <div class="l">
                                <div class="jiag" v-if="item.type == 2"> <span>{{ item.discount }}</span> 折</div>
                                <div class="jiag" v-else-if="item.type == 3"> ￥<span>{{ item.fixationAmount }}</span> </div>
                                <div class="jiag" v-else-if="item.type == 4"> ￥<span>{{ item.premiseAmount }}</span> </div>
                                <div class="jiag" v-else> ￥<span>{{ item.reduceAmount }}</span> </div>
                                <div class="text" v-if="item.type !== '3'">满{{ item.premiseAmount }}可用</div>
                            </div>
                            <div class="r">
                                <div class="name">{{ item.storeName }}</div>
                                <div class="text"> {{ item.suitType == 1 ? '全部商品可用' : '指定商品可用' }}</div>
                                <div class="btn btn3" v-if="item.status == '0'"  @click="searchClick(item)">立即使用</div>
                                <div class="btn btn2" v-if="item.status == '2'">过期失效</div>
                                <div class="btn btn2" v-if="item.status == '1'">已使用</div>
                                <div class="prompt">有效期至 {{ item.createTime }}</div>
                            </div>
                            <img src="@/assets/mjq.png" v-if="item.type == 1" alt="">
                            <img src="@/assets/jeq.png" v-if="item.type == 3" alt="">
                            <img src="@/assets/kmq.png" v-if="item.type == 4" alt="">
                            <img src="@/assets/zkq.png" v-if="item.type == 2" alt="">
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div v-if="myCouponList.length == 0"
                style="width: 100%;text-align: center;margin: 50px auto;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
                <img src="@/assets/yhq.png" style="width: 100px;object-fit: contain;" alt="">
                <div style="width: 100%;margin-top: 10px;">暂无优惠券</div>
            </div>
            <div style="width: 100%;display: flex;justify-content: center;">
                <paging :total="paging.total" @handleCurrentChange="handleCurrentChange" :page-size="paging.sizi"
                    :currentPage="paging.page"></paging>
            </div>

        </div>


    </div>
</template>

<script>
import { getmyCoupon } from "@/utils/api/myApi/index"
import paging from "@/components/paging.vue";
export default {
    name: 'myCoupon',
    components: {
        paging
    },
    data() {
        return {
            activeName: 'first',
            paging: {
                sizi: 10,
                page: 1,
                total: 0,
                status: ''
            },
            // 优惠券列表
            myCouponList: [],
            loading: true
        }
    },
    mounted() {
        this.getCoupon()
    },
    methods: {
        // 根据优惠券搜索商品
        searchClick(i) {
            let spuIds = ''
            if(i.spuIds){
                spuIds = i.spuIds.join(',')
            }
            window.open(`#/search2?storeId=${i.storeId}&type=coupon&spuIds=${spuIds}`, '_blank');
        },
        handleClick(tab, event) {
            this.loading = true
            console.log(tab.index);
            if (tab.index == 0) this.paging.status = ''
            if (tab.index == 1) this.paging.status = 0
            if (tab.index == 2) this.paging.status = 1
            if (tab.index == 3) this.paging.status = 2
            this.getCoupon()
        },
        // 获取优惠券
        async getCoupon() {
            const res = await getmyCoupon({
                status: this.paging.status,
                current: this.paging.page,
                size: this.paging.sizi
            })
            if (res.statusCode == 8201) {
                this.myCouponList = res.data.records
                this.paging.total = res.data.total
                this.loading = false
            }
        },
        // 分页事件
        handleCurrentChange(val) {
            this.loading = true
            this.paging.page = val
            this.getCoupon()
        },
    }
}
</script>

<style lang="less" scoped>
.homeOrder {
    width: 1050px;
    height: auto;

    >.title {
        width: 100%;
        height: 40px;
        background-color: #fff;
        padding: 10px 15px;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }


    >.tabss {
        width: 100%;
        height: auto;
        padding: 20px;
        box-sizing: border-box;
        background-color: #fff;
        margin-top: 20px;
    }

    // 优惠券列表
    .yhqList {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        margin-bottom: 30px;
        gap: 10px;

        >.son {
            width: 330px;
            height: 120px;
            margin-top: 20px;
            background: url(../../assets/lqyhbg.png);
            background-size: 100% 100%;
            display: flex;
            position: relative;

            >.l {
                width: 110px;
                height: 120px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                >.jiag {
                    color: #FF4242;
                    font-size: 16px;

                    >span {
                        font-size: 30px;
                        font-weight: bold;
                    }
                }

                >.text {
                    font-size: 12px;
                    color: #666;
                    margin-top: 5px;
                }
            }

            >.r {
                width: 220px;
                height: 120px;
                padding: 12px 10px;
                box-sizing: border-box;

                >.name {
                    font-size: 14px;
                    color: #333;
                }

                >.text {
                    font-size: 12px;
                    color: #999;
                    margin-top: 2px;
                }

                >.btn {
                    width: 71px;
                    height: 24px;
                    border-radius: 18px;
                    background-color: #FF4242;
                    color: #fff;
                    cursor: pointer;
                    text-align: center;
                    line-height: 24px;
                    font-size: 14px;
                    margin-left: 120px;
                    margin-top: 5px;
                }

                >.btn2 {
                    background-color: #999999;
                }

                >.btn3 {
                    border: 1px solid #FF4242;
                    background-color: rgba(255, 66, 66, .2);
                    color: #FF4242;
                }


                >.prompt {
                    color: #999;
                    font-size: 12px;
                    margin-left: 30px;
                    margin-top: 3px;
                }
            }

            >img {
                width: auto;
                height: 18px;
                position: absolute;
                top: 4px;
                left: 8px;
            }
        }
    }
}

/deep/ .el-tabs__nav-wrap::after {
    background-color: #fff;
}

/deep/ .el-tabs__item.is-active {
    color: #FF4242;
}

/deep/ .el-tabs__active-bar {
    background-color: #FF4242;
}

/deep/ .el-tabs__item:hover {
    color: #FF4242;
}
</style>